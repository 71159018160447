.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.login-layer-limit {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.login-layer-background {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  background-image: url("http://ezprojectorganizer.com/images/login_back_1.jpg");
}

.login-layer-background::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.92);
}

.login-wrapp {
  width: 400px;
  padding: 0 50px;
  overflow: hidden;
}

.loginForm {
  width: 390px;
}
.formControl {
  width: 390px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px !important;
}

@media (max-width: 576px) {
  .loginForm {
    width: 360px;
  }
  .login-wrapp {
    width: 360px;
    padding: 10px;
  }
  .formControl {
    width: 350px;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 360px) {
  .loginForm {
    width: 320px;
  }
  .login-wrapp {
    width: 320px;
    padding: 10px;
  }
  .formControl {
    width: 310px;
    margin-bottom: 10px !important;
  }
}

.login-form-logo {
  font-size: 60px;
  color: #333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;

  padding: 0;
  box-sizing: border-box;
}

.login-form-title {
  /*font-family: Poppins-Medium;*/
  font-size: 30px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  display: block;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.p-b-34 {
  padding-bottom: 34px;
}
.p-t-27 {
  padding-top: 27px;
}

@media screen and (max-width: 1000px) {
}
