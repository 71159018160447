body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.H_ib_body {
  background: rgb(255, 255, 255) !important;
  box-shadow: none !important;
  border: #efefef 1px solid;
}
.H_ib.H_ib_top .H_ib_tail::after {
  border-color: rgb(255, 255, 255) transparent !important;
}
.H_ib_content {
  margin: 1px 4px;
  min-width: 8em;
}
.H_ib_close {
  /*display: none;*/
  top: 2px;
  right: 2px;
}

.jom_el_name {
  font-size: 10px;
  line-height: 1;
}
.jom_el_head {
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  line-height: 14px;
  margin-bottom: 4px;
  cursor: pointer;
}
.jom_el_cont {
  cursor: pointer;
}
.jom_el_col1 {
  display: inline-block;
  width: 20px;
}
.jom_el_col2 {
  display: inline-block;
  /*width: 20px;*/
}
.jom_el_codes {
  font-size: 12px;
  text-align: left;
  line-height: 14px;
}
